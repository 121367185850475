body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #1a2234; */
}

main {
  padding: 0 50px;
  max-width: 900px;
  margin: 50px auto;
}

code {
  color: #bbb;
  font: inherit;
}

code::before {
  content: '$'
}

h1, h2, h3, h4, h5, h6 {
  margin: 12px auto;
  color: black;
}

p, label, input {
  line-height: 1.5em;
  color: rgba(0, 0, 0, 0.8);
}

input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

label, input[type="text"] {
  text-transform: capitalize;
}

input {
  margin: 0 5px;
  font: inherit;
  text-align: right;
}

input:hover, input:focus, input:active {
  -webkit-appearance: none;
  appearance: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.input-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 400px;
}

.input-container > * {
  margin-bottom: 10px;
  margin-right: 10px;
}

.icon-container {
  font: inherit;
  appearance: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  padding: 7px;
}

.icon-container:hover {
  background-color: #eee;
}

@media screen and (max-width: 600px) {
  main {
    margin: 10px auto;
    padding: 0 10px 10px;
  }
}